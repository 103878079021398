import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route,} from "react-router-dom";

import "handsontable/dist/handsontable.min.css";

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'


// import {PPO_Page1} from "./components/Kolon_PPO/PPO_Page1";
// import {PPO_Page2} from "./components/Kolon_PPO/PPO_Page2";
import {FlowTest} from "./components/ProcessMetaverse/flowTest";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            {/*<Route path="/kolon_ppo" element={<PPO_Page1 />} />*/}
            {/*<Route path="/ppo" element={<PPO_Page2 />} />*/}
            <Route path="/" element={<FlowTest />} />
        </Routes>
    </BrowserRouter>

);


