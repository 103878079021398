import React from 'react'
import * as THREE from "three";

import { useFrame, useLoader } from '@react-three/fiber'
import {useGLTF} from '@react-three/drei'
import circleImg from "./circle.png";

let path = "./static/models/SECL (Merged).glb"

let randomIntFromInterval =(min, max) => { // min and max included
    return Math.random() * (max - min) + min
}

let colorList = ['#ff0000','#ff8c00','#2e8b57','#2f4f4f','#1e90ff','#eee8aa']

export const Model = ({props, renderPoint, isPerspective}) => {
    // console.log(props,renderPoint)
    const { nodes, materials } = useGLTF(path)


    let isTrans = props.mode !== 0
    let transRate = props.mode !== 0 ? 0.2 : 1

    let particleRadius = 20

    return (
        <group position={[-11,0,0]} rotation={[0,0,0]} {...props} dispose={null}>
            <LongTube {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                      material='Cool_Steel' count={{dmp: 2000, ea: 0, h2o: 600, o2: 10, ppo: 100}} position={[-5.06, -3.61, -0.05]} length={0.83} direction='LtoR' />

            <group >
                <Cylinder {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                count={[
                    {dmp: 2000, ea: 0, h2o: 600, o2: 10, ppo: 100},
                    {dmp: 1600, ea: 0, h2o: 600, o2: 10, ppo: 200},
                    {dmp: 1800, ea: 0, h2o: 600, o2: 10, ppo: 100}]}/>
            </group>

            <group position={[5.48, 0, 0]} >
                <Cylinder {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                count={[
                    {dmp: 1400, ea: 0, h2o: 600, o2: 10, ppo: 700},
                    {dmp: 1000, ea: 0, h2o: 600, o2: 10, ppo: 200},
                    {dmp: 1200, ea: 0, h2o: 600, o2: 10, ppo: 700}]}/>
            </group>

            <group position={[5.48*2, 0, 0]} >
                <Cylinder {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                count={[
                    {dmp: 800, ea: 0, h2o: 600, o2: 10, ppo: 1300},
                    {dmp: 400, ea: 0, h2o: 600, o2: 10, ppo: 200},
                    {dmp: 600, ea: 0, h2o: 600, o2: 10, ppo: 1300}]}/>
            </group>

            <group position={[5.48*3, 0, 0]} >
                <Cylinder {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                count={[
                    {dmp: 200, ea: 0, h2o: 600, o2: 10, ppo: 1900},
                    {dmp: 100, ea: 0, h2o: 600, o2: 10, ppo: 200},
                    {dmp: 200, ea: 0, h2o: 200, o2: 10, ppo: 2000}]}/>
            </group>
            <LongTube {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                      material='Cool_Steel' count={{dmp: 200, ea: 0, h2o: 200, o2: 10, ppo: 2000}} position={[21.1, -3.61, -0.03]} length={1.4} direction='LtoR' />

            <group position={[0, 0, 0]} >
                <CCR {...props} isTrans={isTrans} transRate={transRate} particleRadius={particleRadius} nodes={nodes} materials={materials} renderPoint={renderPoint} isPerspective={isPerspective}
                count={{dmp: 10, ea: 0, h2o: 600, o2: 10, ppo: 100}}/>
            </group>

        </group>
    )
}

function BufferPoints(props) {
    // console.log(props)

    let v = Math.PI*2*(props.radius/10**2)*props.long*4
    // console.log(props.long, v)
    let count = {
        dmp:props.checkList[0] ? (props.count['dmp']*v).toFixed(0) :0,
        ea:props.checkList[1] ? (props.count['ea']*v).toFixed(0) :0,
        h2o:props.checkList[2] ? (props.count['h2o']*v).toFixed(0) :0,
        o2:props.checkList[3] ? (props.count['o2']*v).toFixed(0) :0,
        ppo:props.checkList[4] ? (props.count['ppo']*v).toFixed(0) :0,
        tmbpa:props.checkList[5] ? (props.count['tmbpa']*v).toFixed(0) :0,
    }

    const pointRef = React.useRef();

    useFrame(({ clock }) => {
        if(props.direction === 'horizontal'){
            pointRef.current.rotation.x = clock.getElapsedTime() * 1;
        }else if(props.direction === 'RtoL' || props.direction === 'LtoR'){
          pointRef.current.rotation.x = -clock.getElapsedTime() * 0.5;
        }else if(props.direction === 'TtoB' || props.direction === 'BtoT' || props.direction === 'column'){
            pointRef.current.rotation.y = -clock.getElapsedTime() * 0.5;
        }

        let position = pointRef.current.geometry.attributes.position

        const point = new THREE.Vector3();

        for ( let i = 0; i < position.count; i ++ ) {
            point.fromBufferAttribute( position, i );
            let speed = 0.01
            if(props.direction === 'horizontal'){
                position.setXYZ( i, point.x, point.y, point.z );
            }else if(props.direction === 'LtoR' || props.direction === 'BtoT'){
                if(point.x < props.long/2){
                    position.setXYZ( i, point.x + speed, point.y, point.z  );
                }else{
                    position.setXYZ( i, -props.long/2, point.y, point.z );
                }
            }else if(props.direction === 'RtoL' || props.direction === 'TtoB'){
                if(point.x > -props.long/2){
                    position.setXYZ( i, point.x - speed, point.y, point.z  );
                }else{
                    position.setXYZ( i, props.long/2, point.y, point.z );
                }
            }else if(props.rotation === '0' || props.rotation === '90' || props.rotation === '180' || props.rotation === '240'){
                // console.log(props.direction)
                if(props.direction === 'ac'){
                    if(point.x < 0.1 || point.y > 0.6){
                        position.setXYZ( i, 0.4 + Math.random() * .15, 0.1, point.z );
                    }else if(point.x < 0.3 || point.y > 0.5){
                        position.setXYZ( i, point.x - speed * Math.random(), point.y, point.z  );
                    }else if(point.x < 0.4 || point.y > 0.35){
                        position.setXYZ( i, point.x - speed * Math.random(), point.y + speed * Math.random(), point.z  );
                    }else{
                        position.setXYZ( i, point.x, point.y + speed * Math.random(), point.z  );
                    }
                }else{
                    if(point.x > 0.6 || point.y < 0.2){
                        position.setXYZ( i, 0.1 + Math.random() * .15, 0.4 + Math.random() * .15, point.z );
                    }else if(point.x < 0.3 || point.y < 0.1){
                        position.setXYZ( i, point.x+ speed * Math.random(), point.y, point.z  );
                    }else if(point.x < 0.5 || point.y < 0.2){
                        position.setXYZ( i, point.x + speed * Math.random(), point.y - speed * Math.random(), point.z  );
                    }else{
                        position.setXYZ( i, point.x, point.y - speed * Math.random(), point.z  );
                    }
                }

            }else{
                // if(point.z < props.long/2){
                //     position.setXYZ( i, point.x, point.y, point.z  + speed);
                // }else{
                //     position.setXYZ( i, point.x, point.y, -props.long/2 );
                // }
            }
        }
        position.needsUpdate = true;

        // var material = points.material;
        // var particle = points.geometry.vertices[0];
        //
        // if (particle.y < -200) {
        //   particle.x = (Math.random() - 0.5) * 100;
        //   particle.y = (Math.random() - 0.5) * 100 + 200;
        //   particle.z = (Math.random() - 0.5) * 100;
        //   particle.velocity.y = 0;
        //   material.size = 20;
        //   material.opacity = 0;
        // }
        //
        // particle.velocity.y -= Math.random() * .1;
        // particle.add(particle.velocity);
        //
        //
        // if ( material.size < 40 )
        //   material.size += 0.25;
        // if ( material.opacity < 1 )
        //   material.opacity += 0.01;
        //
        // points.geometry.verticesNeedUpdate = true;
        // points.rotation.y += (Math.random()*0.001)


    });

    const CircleImg = useLoader(THREE.TextureLoader, circleImg);

    let positions = [];
    let colors = [];


    if(props.isCurved){
        for (let i = 0; i < Object.keys(count).length; i++) {
            for (let j = 0; j < Object.values(count)[i]; j++) {
                let progress = Math.random();

                let color = new THREE.Color(colorList[i])
                let x,y,z
                let radius = randomIntFromInterval(0, props.radius)
                let angle = Math.random() * Math.PI * 2;

                let a = Math.random()*Math.PI*2


                y = Math.sin(angle/4) * 0.5 + progress *0.1
                z = Math.cos(a)*radius
                x = Math.cos(angle/4) * 0.5 + progress *0.1
                // x = progress * props.long - props.long / 2

                colors.push( color.r, color.g, color.b );
                positions.push(x, y, z);
            }
        }
    }else{
        for (let i = 0; i < Object.keys(count).length; i++) {
            for (let j = 0; j < Object.values(count)[i]; j++) {
                let progress = Math.random();

                let color = new THREE.Color(colorList[i])
                let x,y,z
                let radius = randomIntFromInterval(0, props.radius)
                let angle = Math.random() * Math.PI * 2;

                y = Math.cos(angle) * radius
                z = Math.sin(angle) * radius
                x = progress * props.long - props.long / 2

                colors.push( color.r, color.g, color.b );
                positions.push(x, y, z);
            }
        }
    }





    positions =new Float32Array(positions)
    colors = new Float32Array(colors)
    let rotation, position
    if(props.direction === 'column') rotation = [0, 0, Math.PI / 2]
    else if(props.rotation === '0') rotation = [0, 0, Math.PI * 6 / 4]
    else if(props.rotation === '90') rotation = [0, 0, Math.PI * 4 / 4]
    else if(props.rotation === '180') rotation = [0, 0, Math.PI * 2 / 4]
    else if(props.rotation === '240') rotation = [0, 0, Math.PI * 8 / 4]
    else rotation = props.rotation


    if(props.rotation === '0'){
        position = [props.position[0] - 0.38, props.position[1] + 0.38, props.position[2]]
    }else if(props.rotation === '90'){
        position = [props.position[0] + 0.38, props.position[1] + 0.38, props.position[2]]
    }else if(props.rotation === '180'){
        position = [props.position[0] + 0.38, props.position[1] - 0.38, props.position[2]]
    }else if(props.rotation === '240'){
        position = [props.position[0] - 0.38, props.position[1] - 0.38, props.position[2]]
    }else{
        position = props.position
    }

    return (
        <points ref={pointRef} position={position} rotation={rotation}>
            <bufferGeometry>
                <bufferAttribute
                    attach="attributes-position"
                    array={positions}
                    count={positions.length / 3}
                    itemSize={3}
                />
                <bufferAttribute
                    attach="attributes-color"
                    array={colors}
                    count={colors.length / 3}
                    itemSize={3}
                />

            </bufferGeometry>
            <pointsMaterial
                attach="material"
                map={CircleImg}
                // color={0x00aaff}
                vertexColors={true}
                size={(props.isPerspective ? 0.04 : props.particleRadius)}
                sizeAttenuation={true}
                transparent={false}
                alphaTest={0.5}
                opacity={1}
            />
        </points>
    );
}

const Tube = (props) => {
    let rotation
    if(props.direction === 'RtoL'){
        rotation = [0, 0, 0]
    }else if(props.direction === 'LtoR'){
        rotation = [0, 0, 0]
    }else if(props.direction === 'BtoT'){
        rotation = [0 ,0 , Math.PI / 2]
    }else if(props.direction === 'TtoB'){
        rotation = [0, 0, Math.PI / 2]
    }

    return <>
        <mesh geometry={props.nodes['Tube'].geometry} material={props.materials[props.material]} position={props.position} scale={[0.46*props.length,0.39,0.39]} rotation={rotation} material-transparent={props.isTrans} material-opacity={props.transRate} />
        {props.isTrans ?
            <BufferPoints {...props} cellConfig={props.cellConfig} particleRadius={props.particleRadius} long={0.76*props.length} radius={0.1} position={props.position} rotation={rotation} /> : <></>
        }
    </>
}
const CurvedTube = (props) => {
    let rot
    if(props.rotation === '0') rot = [0, 0, 0]
    else if(props.rotation === '90') rot = [0, 0, Math.PI * 6 / 4]
    else if(props.rotation === '180') rot = [0, 0, Math.PI]
    else if(props.rotation === '240') rot = [0, 0, Math.PI * 2 / 4]

    return <>
        <mesh geometry={props.nodes['Curved_Tube'].geometry} material={props.materials[props.material]} position={props.position} scale={0.39} rotation={rot} material-transparent={props.isTrans} material-opacity={props.transRate} />
        {props.isTrans ?
            <BufferPoints {...props} cellConfig={props.cellConfig} particleRadius={props.particleRadius} long={1} radius={0.1} position={props.position} Curved={true} /> : <></>
        }
    </>
}

const LongTube = (props) => {
    let rotation
    if(props.direction === 'RtoL'){
        rotation = [0, 0, 0]
    }else if(props.direction === 'LtoR'){
        rotation = [0, 0, 0]
    }else if(props.direction === 'BtoT'){
        rotation = [0 ,0 , Math.PI / 2]
    }else if(props.direction === 'TtoB'){
        rotation = [0, 0, Math.PI / 2]
    }

    return <>
        <mesh geometry={props.nodes['Long_Tube'].geometry} material={props.materials[props.material]} position={props.position} scale={[0.46*props.length,0.39,0.39]} rotation={rotation} material-transparent={props.isTrans} material-opacity={props.transRate} />
        {props.isTrans ?
            <BufferPoints {...props} cellConfig={props.cellConfig} particleRadius={props.particleRadius} long={4*props.length} radius={0.1} position={props.position} rotation={rotation} /> : <></>
        }
    </>
}

const Cylinder = (props) => {

    let nodes = props.nodes
    let materials = props.materials

    return <>

        <mesh position={[0, 0, 0]} rotation={[0,Math.PI, 0]} geometry={nodes['Cylinder'].geometry} material={materials['Material_Cylinder']} material-transparent={props.isTrans} material-opacity={props.transRate}/>

        <Tube {...props} count={props.count[2]} material='Cool_Steel' position={[0.0, -2.84, -0.03]} length={1} direction='TtoB' />
        <CurvedTube {...props} count={props.count[2]} material='Cool_Steel' position={[0.1, -3.51, -0.03]} rotation='90' direction='ac' />
        <Tube {...props} count={props.count[2]} material='Cool_Steel' position={[1.22, -3.61, -0.03]} length={2.2} direction='LtoR' />

        <LongTube {...props} count={props.count[0]} material='Cool_Steel' position={[-2.36, -2.42, -0.04]} length={0.78} direction='BtoT' />
        <LongTube {...props} count={props.count[0]} material='Cool_Steel' position={[-2.36, 0.68, -0.04]} length={0.78} direction='BtoT' />
        <CurvedTube {...props} count={props.count[2]} material='Cool_Steel' position={[-2.26, 2.46, -0.03]} rotation='180' direction='c' />
        <Tube {...props} count={props.count[0]} material='Cool_Steel' position={[-1.66, 2.56, -0.03]} length={0.86} direction='LtoR' />

        <Tube {...props} count={props.count[1]} material='Heat_Steel' position={[0.9, -2.54, -0.03]} length={1} direction='LtoR' />
        <CurvedTube {...props} count={props.count[1]} material='Heat_Steel' position={[1.58, -2.44, -0.01]} rotation='0' direction='ac' />

        <LongTube {...props} count={props.count[1]} material='Heat_Steel' position={[1.68, -0.6, 0]} length={0.78} direction='BtoT' />
        <LongTube {...props} count={props.count[1]} material='Heat_Steel' position={[1.68, 2.48, 0]} length={0.78} direction='BtoT' />
        <CurvedTube {...props} count={props.count[1]} material='Heat_Steel' position={[1.78, 4.3, -0.01]} rotation='180' direction='c' />
        <LongTube {...props} count={props.count[1]} material='Heat_Steel' position={[3.54, 4.4, 0]} length={0.76} direction='LtoR' />
        <CurvedTube {...props} count={props.count[1]} material='Heat_Steel' position={[5.32, 4.3, -0.01]} rotation='240' direction='c' />
        <Tube {...props} count={props.count[1]} material='Heat_Steel' position={[5.42, 3.64, -0.01]} length={1} direction='TtoB' />

        <mesh geometry={nodes['Heat_Exchanger'].children[0].geometry} material={materials['HexOut']} position={[-.92, -4.76, -0.1]} scale={1.4} rotation={[0, Math.PI*6 / 4, 0]} material-transparent={props.isTrans} material-opacity={props.transRate} />
        <mesh geometry={nodes['Heat_Exchanger'].children[1].geometry} material={materials['Material_Cylinder']} position={[-.92, -4.76, -0.1]} scale={1.4} rotation={[0, Math.PI*6 / 4, 0]} material-transparent={props.isTrans} material-opacity={props.transRate} />
        <CurvedTube {...props} count={props.count[1]} material='Cool_Steel' position={[-3.14, -3.7, -0.04]} rotation='240' direction='c' />

        {props.isTrans ?<>
            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count[0]} particleRadius={props.particleRadius} long={3.8} radius={1} position={[0,-1,-0.1]} rotation={0} direction='column' />
            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count[0]} particleRadius={props.particleRadius} long={2.5} radius={0.7} position={[0,2,-0.1]} rotation={0} direction='column' />

            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count[0]} particleRadius={props.particleRadius} long={4.6} radius={0.5} position={[-1,-4.76,-0.1]} direction='horizontal' />

        </> :<></>}

    </>
}

const CCR = (props) => {
    let nodes = props.nodes
    let materials = props.materials

    return <>
        <mesh geometry={nodes['CCR'].geometry} material={materials['Material_CCR']} position={[21.8, 0.26, 0]} rotation={[0,0, Math.PI]} material-transparent={props.isTrans} material-opacity={props.transRate} />
        <CurvedTube {...props} count={props.count} material='Heat_Steel' position={[21.82, -3.1, 0]} rotation='90' direction='ac' />
        <Tube {...props} count={props.count} material='Heat_Steel' position={[22.48, -3.2, 0]} length={1.02} direction='LtoR' />
        <CurvedTube {...props} count={props.count} material='Heat_Steel' position={[23.16, -3.1, 0]} rotation='0' direction='ac' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[23.26, -0.91, 0]} length={1} direction='BtoT' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[23.26, 3.02, 0]} length={1} direction='BtoT' />
        <CurvedTube {...props} count={props.count} material='Heat_Steel' position={[23.16, 5.18, 0.02]} rotation='240' direction='ac' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[20.91,5.26,0]} length={1} direction='RtoL' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[16.98, 5.26, 0]} length={1} direction='RtoL' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[13.05, 5.26, 0]} length={1} direction='RtoL' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[9.12, 5.26, 0]} length={1} direction='RtoL' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[5.19, 5.26, 0]} length={1} direction='RtoL' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[1.76, 5.26, 0]} length={0.78} direction='RtoL' />
        <CurvedTube {...props} count={props.count} material='Heat_Steel' position={[-0.04, 5.16, 0]} rotation='180' direction='ac' />
        <LongTube {...props} count={props.count} material='Heat_Steel' position={[-0.14, 3.34, 0]} length={0.78} direction='TtoB' />


        {props.isTrans ?<>
            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count} particleRadius={props.particleRadius} long={3.8} radius={0.96} position={[21.86,1.2,-0.1]} direction='column' />
            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count} particleRadius={props.particleRadius} long={1.6} radius={0.7} position={[21.74,-1.5,-0.1]} direction='column' />
            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count} particleRadius={props.particleRadius} long={0.24} radius={0.5} position={[21.74,-2.4,-0.1]} direction='column' />
            <BufferPoints {...props} cellConfig={props.cellConfig} count={props.count} particleRadius={props.particleRadius} long={0.2} radius={0.4} position={[21.74,-2.6,-0.1]} direction='column' />
            {/*<BufferPoints {...props} cellConfig={props.cellConfig} count={props.count} particleRadius={props.particleRadius} long={0.4} radius={0.1} position={[21.7,-2.9,0]} direction='TtoB' />*/}
        </> :<></>}
    </>
}

useGLTF.preload(path)