import React, {useState, useEffect, useRef} from "react";
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import './test.scss'
import {Model} from "./Model"


export const FlowTest = () => {

    const [ mode, setMode ] = useState(1)
    const [ checkList, setCheckList ] = useState([true,true,true,false,false])


    return <div className="testPage">
        <div className="fccHeader">
            {/*<img className="hscImg" src={hscLogo} alt="" />*/}
            Process Metaverse
        </div>

        <div className="infoCon" style={{padding:'0px 8px',display:'flex', flexDirection:'column'}}>
            <div>
                <button onClick={()=> setMode(0)} > Default </button>
                <button onClick={ async ()=> {
                    setMode(0)
                    await new Promise(resolve => setTimeout(resolve, 1));
                    setMode(1)

                    let list = [...checkList]
                    list[0] = true
                    list[2] = true
                    list[4] = true
                    setCheckList(list)
                }} > Mode 1 </button>
                <button onClick={ async ()=> {
                    setMode(0)
                    await new Promise(resolve => setTimeout(resolve, 1));
                    setMode(2)
                    let list = [...checkList]
                    list[0] = true
                    list[2] = false
                    list[4] = false
                    setCheckList(list)
                }} > Mode 2 </button>
                <button onClick={ async ()=> {
                    setMode(0)
                    await new Promise(resolve => setTimeout(resolve, 1));
                    setMode(3)
                    let list = [...checkList]
                    list[0] = false
                    list[2] = true
                    list[4] = false
                    setCheckList(list)

                }} > Mode 3 </button>
                <button onClick={ async ()=> {
                    setMode(0)
                    await new Promise(resolve => setTimeout(resolve, 1));
                    setMode(4)
                    let list = [...checkList]
                    list[0] = false
                    list[2] = false
                    list[4] = true
                    setCheckList(list)
                }} > Mode 4 </button>
            </div>
            <Config mode={mode} checkList={checkList} />
        </div>
    </div>
}


function Animate({ controls,lerping, to, target, isPerspective }) {
    useFrame(({ camera }, delta) => {
        if (lerping) {
            // console.log(camera)
            console.log(controls.current)
            if(isPerspective){
                camera.position.lerp(to, delta * 2)
            }else{
                camera.position.lerp(to, delta * 2)
                // controls.current.target.lerp(target, delta * 2)
                camera.zoom = target/10
                camera.updateProjectionMatrix();

                // controls.current.setLookAt(0,0,0,0,0,0, true)
            }
            // camera.target.lerp(target, delta * 2)
        }
    })
}

const Config = (props) => {
    const ref = useRef()
    const [lerping, setLerping] = useState(false)
    const [to, setTo] = useState()
    const [target, setTarget] = useState()
    const [isPerspective, setIsPerspective] = useState(true )

    const [rerender, setRerender] = useState(false);


    useEffect(() => {
        setRerender(false)
        setTimeout(async () => {
            setRerender(true)
            console.log('Changed Config')
        }, 1)
    }, [props.checkList, props.mode]);


    return (
        <div className="" style={{ height:'80vh',width:'100%',border:'1px solid grey'}}>
            <Canvas shadows gl={{ antialias: true, preserveDrawingBuffer: true }} orthographic={!isPerspective} id="canvas"
                    camera={isPerspective ? {position:[-5,0,10],fov:75,near:0.01,far:1000} : { zoom: 26, position: [0, 10, 20] }}
            >
                <Model props={props} renderPoint={rerender} isPerspective={isPerspective} />

                <ambientLight />
                <spotLight angle={0.25} penumbra={0.5} position={[10, 10, 5]} />
                <hemisphereLight intensity={0.45} />
                <spotLight angle={0.4} penumbra={1} position={[20, 30, 2.5]} shadow-bias={-0.00001} />
                <directionalLight intensity={1} position={[0, 56, 20]} />
                <ambientLight color={"#ffffff"} intensity={1} />

                <OrbitControls ref={ref} target={[0, 0, 0]} autoRotate={false} enableZoom={true}/>
                {/*<color attach="background" args={['white']} />*/}
                {/*<axesHelper args={[20, 20, 20]} />*/}
                <Animate controls={ref} lerping={lerping} to={to} target={target} isPerspective={isPerspective} />
            </Canvas>
        </div>

    )
}